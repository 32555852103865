@import './components/styleguide/variables.scss';

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

@font-face {
	font-family: 'Futura';
	font-style: normal;
	font-display: swap;
	font-weight: 700;
	src:
		url('./assets/fonts/FuturaLT-Bold.woff2') format('woff2'),
		url('./assets/fonts/FuturaLT-Bold.woff') format('woff');
}

@font-face {
	font-family: 'Futura';
	font-style: normal;
	font-display: swap;
	font-weight: 400;
	src:
		url('./assets/fonts/FuturaLT-Regular.woff2') format('woff2'),
		url('./assets/fonts/FuturaLT-Regular.woff') format('woff');
}

@font-face {
	font-family: 'Arial';
	font-style: normal;
	font-display: swap;
	font-weight: 700;
	src:
		url('./assets/fonts/Arial-BoldMT.woff2') format('woff2'),
		url('./assets/fonts/Arial-BoldMT.woff') format('woff');
}

@font-face {
	font-family: 'Arial';
	font-style: normal;
	font-display: swap;
	font-weight: 400;
	src:
		url('./assets/fonts/ArialMT-Regular.woff2') format('woff2'),
		url('./assets/fonts/ArialMT-Regular.woff') format('woff');
}

body {
	margin: 0;
	font-family: 'Futura', 'Nunito', 'Open Sans', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: $primaryColor;
	font-size: 18px;
	font-weight: 700;
	line-height: 25px;
}

a {
	color: $primaryColor;
	font-weight: 700;
	text-decoration: none;
	cursor: pointer;
}
