// SCREENS
$xxs: 375px;
$xs: 568px;
$sm: 768px;
$smd: 992px;
$md: 1024px;
$lg: 1280px;
$xl: 1440px;

// COLORS
$primaryColor: #062d64;
$disabledPrimaryColor: #8296b2;
$secondaryColor: #fbba00;
$white: #fff;
$disabledColor: #dfdfdf;
$BG_GrayColor: #f5f5f5;
$BG_Tooltip_GrayColor: #d9d9d9;
$BG_TileColor: #e3e1e1;
$textGray: #9a9a9a;
$errorColor: #f26326;
$orangeColor: #f28d26;
$greenColor: #009688;
$blueColor: #23a0e9;

// SIZES
$btnHeightDesktop: 45px;
$btnHeightMobile: 38px;
