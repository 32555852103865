@import '../../components/styleguide/variables.scss';

.history__container {
	border: 2px solid $BG_TileColor;
	margin: 35px 0;
}

.history__data {
	display: grid;
	flex-wrap: wrap;
	gap: 20px;
	padding: 15px 20px;

	& > div {
		word-break: break-word;
	}

	@media screen and (min-width: $xxs) {
		grid-template-columns: repeat(2, auto);
	}

	@media screen and (min-width: $sm) {
		grid-template-columns: repeat(4, max-content);
		padding: 25px 30px;
	}

	span {
		font-family: Arial, Helvetica, sans-serif;
		color: $textGray;
		font-weight: 400;
	}
}

.position_data {
	display: grid;
	flex-wrap: wrap;

	padding: 15px 20px;

	& > div {
		word-break: break-word;
	}

	@media screen and (min-width: $xxs) {
		grid-template-columns: repeat(2, auto);
	}

	@media screen and (min-width: $sm) {
		grid-template-columns: repeat(4, max-content);
		padding: 25px 30px;
	}

	span {
		font-family: Arial, Helvetica, sans-serif;
		color: $textGray;
		font-weight: 400;
	}
}

.history__tab-group {
	margin-top: 30px;
}

.history__searchbar {
	@media screen and (min-width: $xs) {
		width: 300px;
	}
}

.history__filters {
	max-width: 100%;
	margin-left: auto;
	row-gap: 10px;
	column-gap: 25px;
	display: grid;
	grid-template-columns: 1fr;

	@media screen and (min-width: $sm) {
		grid-template-columns: 1fr auto;
	}

	.dropdown-group {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;

		& > * {
			width: 100%;

			@media screen and (min-width: $xs) {
				width: auto;
			}
		}
	}

	.datepicker {
		position: relative;
		display: grid;
		align-items: end;

		&__label {
			font-family: Arial, Helvetica, sans-serif;
			color: $textGray;
			padding: 3px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid $primaryColor;
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 1;
			width: 100%;

			svg {
				transform: rotate(180deg);
			}
		}

		&__input {
			z-index: 2;
		}
	}
}

.history__group {
	margin-top: 45px;
}

.history__empty {
	margin: 0 auto;
	display: grid;
	justify-items: center;
	grid-gap: 30px;

	@media screen and (min-width: $xs) {
		width: 70%;
	}
}

// the styling for the order archive
.history__archive {
	border-top: 2px solid $BG_TileColor;

	&-name {
		padding: 15px 30px;
	}
}

// the stylings for the webshop orders
.history__orders {
	border-top: 2px solid $BG_TileColor;
	display: grid;
	grid-template-columns: 0.9fr 1fr;
	grid-template-areas:
		'item-img item-name'
		'item-img item-reorder';
	grid-gap: 15px;
	min-height: 115px;
	padding-right: 10px;

	@media (min-width: $xs) {
		grid-template-columns: 0.6fr 1fr;
	}

	@media (min-width: $sm) {
		grid-template-columns: 180px 1fr max-content;
		grid-template-areas: 'item-img item-name item-reorder';
		grid-gap: 25px;
		padding-right: 25px;
		padding-bottom: 0;
		height: 115px;
	}

	& > div,
	a {
		@media (min-width: $sm) {
			align-self: center;
		}
	}

	&-image {
		grid-area: item-img;
		background-color: $BG_GrayColor;
		display: flex;
		justify-content: center;
		align-items: center;

		@media (min-width: $sm) {
			height: 100%;
		}

		& > img {
			max-height: 115px;
			max-width: 100%;

			@media (min-width: $sm) {
				max-height: 95px;
			}
		}
	}

	&-name {
		grid-area: item-name;
		padding-top: 15px;

		@media (min-width: $sm) {
			padding-top: 0;
		}
	}

	&-key {
		grid-area: item-key;
		font-family: Arial, Helvetica, sans-serif;
		font-weight: 400;
	}

	&-reorder {
		grid-area: item-reorder;
		padding-bottom: 15px;
		text-transform: uppercase;
		cursor: pointer;

		@media (min-width: $sm) {
			justify-self: end;
			text-align: end;
			padding-bottom: 0;
		}
	}
}

.history__spinner-container {
	margin: auto;
	width: 50%;
	position: relative;
}

.position__container {
	border: 1px solid $BG_TileColor;
}

.history__open_positions {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: auto;
}

.history__open_positions_overdue {
	color: red;
}
