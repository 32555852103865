@import '../styleguide/variables.scss';

.notification__disabled {
	filter: brightness(95%);
	cursor: default;
}

.notification {
	min-height: 70px;
	border-top: 2px solid $secondaryColor;
	padding: 10px 15px;
	display: grid;
	grid-template-columns: min-content max-content min-content;
	grid-column-gap: 10px;
	grid-template-areas:
		'thumbnail name name'
		'thumbnail priceOrQty priceOrQty'
		'thumbnail enddate enddate';

	@media (min-width: $xs) {
		grid-column-gap: 20px;
		grid-template-areas:
			'thumbnail name name'
			'thumbnail priceOrQty button'
			'thumbnail enddate button';
	}

	&:first-child {
		border-top: none;
	}

	& > * {
		font-family: Arial, Helvetica, sans-serif;
		font-size: 14px;
		line-height: 20px;
		text-transform: capitalize;
	}

	&__thumbnail {
		grid-area: thumbnail;
		align-self: center;
		width: 60px;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	&__name {
		grid-area: name;
		width: 190px;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;

		@media (min-width: $xs) {
			width: 285px;
		}
	}

	&__price {
		grid-area: priceOrQty;
		font-weight: 400;
	}

	&__enddate {
		grid-area: enddate;
	}
}

button {
	display: none;
	grid-area: button;

	@media (min-width: $xs) {
		display: flex;
	}
}
